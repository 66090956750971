import React, { Suspense, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";

// Lazy load các component
const HomePage = lazy(() => import("../USER/HomePage"));
const SettingPage = lazy(() => import("../USER/SettingPage"));
const SummarizePage = lazy(() => import("../SummarizePage/SummarizePage"));
const UploadFile = lazy(() => import("../USER/UploadFile"));
const TasksListDetail = lazy(() => import("../USER/TasksListDetail"));
const LoginPage = lazy(() => import("../ANONYMOUS/LoginPage"));
const NotFoundPage = lazy(() => import("../NOTFOUND/NotFound")); // 🔹 Import trang 404

// Khởi tạo router
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <HomePage />
      </Suspense>
    ),
    children: [
      {
        path: "settings",
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <SettingPage />
          </Suspense>
        ),
      },
      {
        path: "summarize/:sectionId",
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <SummarizePage />
          </Suspense>
        ),
      },
      {
        path: "upload",
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <UploadFile />
          </Suspense>
        ),
      },
      {
        path: "summarize/:sectionId/tasks-list",
        element: (
          <Suspense fallback={<LoadingScreen />}>
            <TasksListDetail />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "login",
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: "*", // 🔹 Route cho các đường dẫn không hợp lệ
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
]);

const AppRouter = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default AppRouter;
