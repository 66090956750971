import "animate.css";
import { Layout } from "antd";
import i18next from "i18next";
import React, { Suspense } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "./App.css";
import global_en from "./locales/en/global.json";
import global_vi from "./locales/vi/global.json";
import AppRouter from "./pages/PublicRouters/AppRouter.jsx";
import { StoreProvider } from "./StoreProvider.jsx";
import GlobalTheme from "./theme/global.theme.jsx";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen.jsx";
const savedLang = localStorage.getItem("selectedLanguage") || "vi";
i18next.init({
  interpolation: { escapeValue: false },
  lng: "auto",
  fallbackLng: savedLang,
  resources: {
    en: { global: global_en },
    vi: { global: global_vi },
  },
});

const App = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <I18nextProvider i18n={i18next}>
        <GlobalTheme>
          <StoreProvider>
            <AppRouter /> {/* Route tổng thể */}
          </StoreProvider>
        </GlobalTheme>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
